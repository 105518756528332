.custom-close .close {
  color: white;
}

.custom-close .close:hover {
  color: rgb(241, 10, 10);
  /* Keep the hover color white as well */
}

/* .cloButtonH{
	margin-left: 365px;
  } */
.modalHeader {
  background-color: rgb(0, 71, 103);
  color: white;
  padding: 15px;

}

.mBody {
  background-color: #abb8c3;
  color: black;
  font-weight: 700;
}

.contacModal {
  width: 80%;
}

.responsive-imgDebt {
  width: 100%;
  /* Image ko div ke andar fit karne ke liye */
  height: 100%;
  /* Aspect ratio maintain karne ke liye */
  display: block;
  /* Default margin ko remove karne ke liye */
  max-width: 100%;
  /* Ensure karein ki image apne container se bahar na jaye */
}