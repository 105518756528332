img.wp-smiley,
img.emoji {
	display: inline !important;
	border: none !important;
	box-shadow: none !important;
	height: 1em !important;
	width: 1em !important;
	margin: 0 0.07em !important;
	vertical-align: -0.1em !important;
	background: none !important;
	padding: 0 !important;
}

body {
	--wp--preset--color--black: #000000;
	--wp--preset--color--cyan-bluish-gray: #abb8c3;
	--wp--preset--color--white: #ffffff;
	--wp--preset--color--pale-pink: #f78da7;
	--wp--preset--color--vivid-red: #cf2e2e;
	--wp--preset--color--luminous-vivid-orange: #ff6900;
	--wp--preset--color--luminous-vivid-amber: #fcb900;
	--wp--preset--color--light-green-cyan: #7bdcb5;
	--wp--preset--color--vivid-green-cyan: #00d084;
	--wp--preset--color--pale-cyan-blue: #8ed1fc;
	--wp--preset--color--vivid-cyan-blue: #0693e3;
	--wp--preset--color--vivid-purple: #9b51e0;
	--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
	--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
	--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
	--wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
	--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
	--wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
	--wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
	--wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
	--wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
	--wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
	--wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
	--wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
	--wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
	--wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
	--wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
	--wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
	--wp--preset--duotone--midnight: url('#wp-duotone-midnight');
	--wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
	--wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
	--wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
	--wp--preset--font-size--small: 13px;
	--wp--preset--font-size--medium: 20px;
	--wp--preset--font-size--large: 36px;
	--wp--preset--font-size--x-large: 42px;
	--wp--preset--spacing--20: 0.44rem;
	--wp--preset--spacing--30: 0.67rem;
	--wp--preset--spacing--40: 1rem;
	--wp--preset--spacing--50: 1.5rem;
	--wp--preset--spacing--60: 2.25rem;
	--wp--preset--spacing--70: 3.38rem;
	--wp--preset--spacing--80: 5.06rem;
	--wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
	--wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
	--wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
	--wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
	--wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}

:where(.is-layout-flex) {
	gap: 0.5em;
}

body .is-layout-flow>.alignleft {
	float: left;
	margin-inline-start: 0;
	margin-inline-end: 2em;
}

body .is-layout-flow>.alignright {
	float: right;
	margin-inline-start: 2em;
	margin-inline-end: 0;
}

body .is-layout-flow>.aligncenter {
	margin-left: auto !important;
	margin-right: auto !important;
}

body .is-layout-constrained>.alignleft {
	float: left;
	margin-inline-start: 0;
	margin-inline-end: 2em;
}

body .is-layout-constrained>.alignright {
	float: right;
	margin-inline-start: 2em;
	margin-inline-end: 0;
}

body .is-layout-constrained>.aligncenter {
	margin-left: auto !important;
	margin-right: auto !important;
}

body .is-layout-constrained> :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
	max-width: var(--wp--style--global--content-size);
	margin-left: auto !important;
	margin-right: auto !important;
}

body .is-layout-constrained>.alignwide {
	max-width: var(--wp--style--global--wide-size);
}

body .is-layout-flex {
	display: flex;
}

body .is-layout-flex {
	flex-wrap: wrap;
	align-items: center;
}

body .is-layout-flex>* {
	margin: 0;
}

:where(.wp-block-columns.is-layout-flex) {
	gap: 2em;
}

.has-black-color {
	color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
	color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
	color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
	color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
	color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
	color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
	color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
	color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
	color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
	color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
	color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
	color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-background-color {
	background-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
	background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
	background-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-background-color {
	background-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
	background-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
	background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
	background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
	background-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
	background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
	background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
	background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
	background-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-border-color {
	border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
	border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
	border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
	border-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
	border-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
	border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
	border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
	border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
	border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
	border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
	border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
	border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
	background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
	background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
	background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
	background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
	background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
	background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
	background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
	background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
	background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
	background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
	background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
	background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
	font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
	font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
	font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
	font-size: var(--wp--preset--font-size--x-large) !important;
}

.wp-block-navigation a:where(:not(.wp-element-button)) {
	color: inherit;
}

:where(.wp-block-columns.is-layout-flex) {
	gap: 2em;
}

.wp-block-pullquote {
	font-size: 1.5em;
	line-height: 1.6;
}

header h1 {
	color: #fff
}

.acenabtn a {
	background: #f00 !important;
	border-radius: 12px;
	margin-top: 3px;
	margin-bottom: 2px;
}

div#\37 ZLoN img {
	width: 100%;
}

div#aaQfL img {
	width: 100%;
}

a.lhover {
	font-size: 16px;
	font-weight: 700;
	color: rgb(0, 71, 103);
}

a.lhover:hover {
	color: #00b1cc;
}

div#biicE img {
	width: 100%;
}

button.btn.btn-primary.acenna {
	height: 51px;
	width: 250px;
	background: #73ded9;
	margin-top: 7px;
	margin-bottom: 16px;
	border-radius: 50px;
	border: none;
}

button.btn.btn-primary.acenna:hover {
	background: #00b1cc;
}

div#zUnLA img {
	width: 100%;
}

.icon-links.icon-social.icon-links-grid.social-colors {
	margin-left: 57px;
	margin-top: 0px;
}

.tab-nav li {
	list-style: none;
	display: none;
}

p.brdot {
	color: white;
}

div#\33 XB2A img {
	width: 100%;
}

#AVu2O {
	margin-left: -20%;
	margin-right: -20%;
}

.section-map.section-full-width>.container {
	max-width: 50%;
	display: none;
}

a#yqJCj {
	display: none;
}

a#\32 HDpf {
	display: none;
}

div#AjvVa {
	height: 360px;
}

a.googlebtn img {
	margin-left: -8px;
	width: 267px;
	height: 80px;
	margin-top: -9px;
}

ul#xXDlH {
	margin-left: -25px;
}

.cnt-box-info .caption p {
	margin-top: -32px !important;
}

div#klCaA {
	height: 485px;
	background-color: #fff;
}

div#nSo4S {
	height: 484px;
}

h4.displynone {
	color: white;
}

p.pjustify {
	text-align: justify;
}

p.ppjustify {
	text-align: justify;
}

h4.mtop {
	margin-top: 7px;
}

p.andrdapdwnld {
	margin-left: 12px;
}

.menu-mini .menu-right {
	left: 0px;
	text-align: center;
	width: 330px;
	right: 0px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	padding-left: 0px !important;
}

.menu-mini {
	background: lightblue;

}

.menu-fixed.scroll-menu .menu-mini {
	background: transparent;
	border-bottom: 0px;
}

.menu-mini .menu-right .menu-custom-area {
	border-color: transparent;
}

.buttonizer-label.buttonizer-label-0-0-6 {
	background: #03BFCB !important;
	width: 194px;
	height: 49px;
	border-radius: 50px !important;
	font-size: 15px !important;
}

p.stickybtn {
	color: #F64E60;
	margin-right: -74px;
	font-size: 0px;
	margin-top: 13px;
}

p.stickybtnhover {
	color: white;
	margin-right: 30px !important;
	margin-top: 9px !important;
}

.buttonizer-label-0-0-6 {


	display: none;
}

:not(#\ ):not(#\ ):not(#\ ):not(#\ ):not(#\ ):not(#\ ).buttonizer-button-0-0-3::before {

	content: "Demo" !important;
	margin-top: 13px !important;

}

a.buttonizer-button.buttonizer-button-0-0-3:hover .buttonizer-label.buttonizer-label-0-0-6 {
	display: block;
}


label.phonlbl {
	margin-left: 162px;
}

input.wpcf7-form-control.wpcf7-date.wpcf7-validates-as-required.wpcf7-validates-as-date {
	width: 85%;
}

select.wpcf7-form-control.wpcf7-select.wpcf7-validates-as-required {
	width: 90px;
}

label.demodatelbl {
	margin-left: 131px;
}

.contactUsForm input[type="text"] {
	/* background-color: #fff; */
	color: #000;
	width: 90.6%;
}

.contactUsForm input[type="email"] {
	/* background-color: #fff; */
	color: #000;
	width: 85%;
}

.contactUsForm input[type="tel"] {
	background-color: #fff;
	color: #000;
	width: 85%;
}

@media (max-width: 767px) {
	div#bFZdW h2 {
		font-size: 24px;
	}

	#content1::after {
		content: "\a";
		white-space: pre;
	}

	#wukyI li {
		position: relative;
		list-style: disc !important;
		padding-left: 0px;
		margin-bottom: 10px;
	}

	#wukyI li::marker {
		color: #03BFCB;
		;
		font-size: 20px;
	}

	#wukyI li:before {
		display: none;
	}

	div#klCaA {
		margin-left: 25px;
		margin-right: 25px;
	}

	div#tgo4j {
		margin-left: 25px;
		margin-right: 25px;
	}

	div#nSo4S {
		margin-left: 25px;
		margin-right: 25px;
	}

	table#cDX4o tr {
		display: flex;
		justify-content: space-between;
	}

	table#cDX4o th {
		width: 100%;
	}

	table#cDX4o td {
		width: 100%;
	}
}

/* ======================================================================== */

@keyframes scroll {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(calc(-290px * 7));
	}
}

.clientslider .slider {
	height: 70px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: auto;
	background: #fff;

}

.clientslider .slider .slide-track {
	animation: scroll 40s linear infinite;
	display: flex;
	width: calc(290px * 14) !important;
}

.clientslider .slider .slide {
	height: 70px;
	/* width: 250px; */
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 15px;
}

.clientslider {
	background-color: #fff;
	padding: 70px 30px;
}

.contactSendMsg {
	margin-top: 30px;
	text-align: center;
}

.flexitems-center {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 90px;
}

.acenna_backHome {
	padding: 9px 27px;
	border-radius: 20px;
	margin-bottom: 30px;
}
#Linkedin123 {
	font-size: 25px;
	font-weight: bold;
	color: black !important;
	display: flex;
	align-items: center;
	margin-left: 58px;
	
}
#Linkedinlogo123{
	border-radius: 4px !important;
}
/* ----------- Loader Code ------------------ */
.mainloadercomponent {
	background: rgba(225, 225, 225, 0.7);
	width: 100%;
	height: 100vh;
	left: 0%;
	top: 0px;
	position: fixed;
	z-index: 11;
	display: grid;
	place-content: center;
}

.mainloadercomponentLoad {
	background: rgba(225, 225, 225, 0.7);
	width: 100%;
	height: 100vh;
	left: 0%;
	top: 0px;
	position: fixed;
	z-index: 11;
	display: grid;
	place-content: center;
}

.mainloadercomponentLoad img {
	max-width: 150px;
}

.mainloadercomponent img {
	max-width: 150px;
}

.img-source1 img {
	max-width: 134px;
}

.img-source1 {
	margin-top: 0px;
	font-size: 13px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* ----------- Loader Code ------------------ */